let mixin = {
  data() {
    return {}
  },
  methods: {
    searchDevice: function() {
      return new Promise(resolve => {
        let categoryId = ''
        for (let i = 0; i < this.selectAssetCategoryList.length; i++) {
          let info = this.selectAssetCategoryList[i]
          categoryId = categoryId + (categoryId == '' ? '' : ',') + info.id
        }

        let modelId = ''
        for (let i = 0; i < this.selectModelList.length; i++) {
          let info = this.selectModelList[i]
          modelId = modelId + (modelId == '' ? '' : ',') + info.id
        }

        let brandId = ''
        for (let i = 0; i < this.selectBrandList.length; i++) {
          let info = this.selectBrandList[i]
          brandId = brandId + (brandId == '' ? '' : ',') + info.id
        }

        let tenantId = ''
        for (let i = 0; i < this.selectOrgList.length; i++) {
          let info = this.selectOrgList[i]
          tenantId = tenantId + (tenantId == '' ? '' : ',') + info.id
        }

        let roomPlace = ''
        for (let i = 0; i < this.selectSimpleList.length; i++) {
          let info = this.selectSimpleList[i]
          if (info.id == '') {
            roomPlace = ''
            break
          }
          roomPlace = roomPlace + (roomPlace == '' ? '' : ',') + info.place
        }
        this.$api.assetModule.getSearchDeviceList({
          roomPlace:roomPlace,
          query: this.inPut,
          categoryId: categoryId,
          brandId: brandId,
          modelId: modelId,
          page: this.page,
          pageSize: this.pageSize,
          deleted: 0,
          order: '',
          orderType: '',
          tenantId: tenantId,
          isTenantIdChild: 1,
          isCategoryIdChild: 1,
          status: '7',
          type: '',
          industryId: '',
          checkStatus: ''
        }).then(res => {
          let list = []
          if (typeof res.data != 'undefined' && res.data != null) {
            list = res.data
          }
          let totalCount = 0
          if (typeof res.totalCount != 'undefined' && res.totalCount != null) {
            totalCount = res.totalCount
          }
          resolve({ list: list, totalCount: totalCount })
        }).catch(e => {
          resolve({ list: [], totalCount: 0 })
        })
      })
    },
    dialogClose: function() {
      this.dialogVisible = false
    },
    dialogSure: function(res) {
      this.dialogVisible = res.dialogVisible
      if (this.dialogType == 'asset') {
        this.selectAssetCategoryList = res.nodes
      } else if (this.dialogType == 'brand') {
        this.selectBrandList = res.nodes
      } else if (this.dialogType == 'org') {
        this.selectOrgList = res.nodes
      } else if (this.dialogType == 'simple') {
        this.selectSimpleList = res.nodes
      } else {
        this.selectModelList = res.nodes
      }
      this.currentChange(1)
    },
    getSimpleList: function() {
      let info = {
        id: '',
        name: '全部',
        children: []
      }
      return new Promise(resolve => {
        this.orgList = []
        this.$api.assetModule.theaterSimpleList({}).then(res => {
          if (this.$valueIsExist(res, 'data') == false) {
            resolve({ list: [info], selectList: [info] })
            return
          }
          let list = [info]
          let selectList = [info]

          try {
            for (let i = 0; i < res['data'].length; i++) {
              let data = res['data'][i]
              info.children.push({
                id: data.id,
                name: data.place + ' - ' + data.roomName,
                place: data.place
              })
            }
          } catch (e) {

          }
          resolve({ list: list, selectList: selectList })
        }).catch(e => {
          resolve({ list: [info], selectList: [info] })
        })
      })
    },

    getAllData: function() {
      this.loadCategory = true
      this.loadModel = true
      this.loadBrand = true
      this.loadOrg = true
      this.loadSimple = true
      this.getAssetCategory().then(res => {
        this.loadCategory = false
        this.categoryList = res.list
        this.selectAssetCategoryList = res.selectList
      })

      this.getBrandList().then(res => {
        this.loadBrand = false
        this.brandList = res.list
        this.selectBrandList = res.selectList
      })

      this.getModel().then(res => {
        this.loadModel = false
        this.modelList = res.list
        this.selectModelList = res.selectList
      })

      this.getOrg().then(res => {
        this.loadOrg = false
        this.orgList = res.list
        this.selectOrgList = res.selectList
        this.loadOrg = false
      })

      this.getSimpleList().then(res => {
        this.loadSimple = false
        this.simpleList = res.list
        this.selectSimpleList = res.selectList
      })

    },
    // 获取子机构科室
    getOrg: function() {
      return new Promise(resolve => {
        this.orgList = []
        this.$api.userInfo.getListTreeTenantAuthority({}).then(res => {
          let jsonStr = JSON.stringify(res.data)
          jsonStr = jsonStr.replace(/title/g, 'name')
          jsonStr = jsonStr.replace(/child/g, 'children')

          let list = []
          let selectList = []

          try {
            list = JSON.parse(jsonStr)
            let userInfo = this.$store.getters.userInfo
            let tenantId = userInfo.tenantId
            for (let i = 0; i < list.length; i++) {
              let org = list[i]
              if (org.id == tenantId) {
                selectList.push(org)
                if (i == 0) {
                  org.id = ''
                }
                break
              }
            }
          } catch (e) {

          }
          resolve({ list: list, selectList: selectList })
        }).catch(e => {
          resolve({ list: [], selectList: [] })
        })
      })
    },
    // 获取资产分类
    getAssetCategory: function() {
      return new Promise(resolve => {
        this.assetCategoryList = []
        this.$api.assetModule.getDeviceCategoryTree({}).then(res => {
          let list = [{
            id: '',
            name: '全部资产分类',
            parentId: '',
            parentName: '',
            children: res.data
          }]
          let selectList = list
          resolve({ list: list, selectList: selectList })
        }).catch(e => {
          resolve({ list: [], selectList: [] })
        })
      })
    },
    getBrandList: function() {
      return new Promise(resolve => {
        this.brandList = []
        this.selectBrandList = []
        this.$api.assetModule.getBrandList({ getAll: 1 }).then(res => {
          let data = []
          if (typeof res.data != 'undefined' && res.data != null) {
            data = res.data
          }
          let list = [{
            id: '',
            name: '全部品牌',
            parentId: '',
            parentName: '',
            children: data
          }]
          let selectList = list
          resolve({ list: list, selectList: selectList })
        }).catch(e => {
          resolve({ list: [], selectList: [] })
        })
      })
    },
    // 获取品牌型号
    getModel: function() {
      return new Promise(resolve => {
        this.modelList = []
        this.selectModelList = []
        this.$api.assetModule.getModelList({ getAll: 1 }).then(res => {
          let data = []
          if (typeof res.data != 'undefined' && res.data != null) {
            data = res.data
          }
          let list = [{
            id: '',
            name: '全部型号',
            parentId: '',
            parentName: '',
            children: data
          }]
          let selectList = list
          resolve({ list: list, selectList: selectList })
        }).catch(e => {
          resolve({ list: [], selectList: [] })
        })
      })
    }

  }
}

export default mixin
