<template>
  <div class="searchDevice">
    <h-stack class="searchFillter" align-items="flex-start">
      <div class="searchFillterBGView">
        <el-input
            size="mini"
            placeholder="请输入内容"
            v-model="inPut"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>

        <div class="flllterChoose">
          <el-button style="height: 30px"  size="mini" type="primary" @click="showTree('asset')"
                     :loading="loadCategory"
          >资产分类
          </el-button>
          <h-stack flex-wrap="wrap" justify-content="flex-start" align-items="flex-start"
                   class="checkAssetChooseSelect"
          >
            <div class="checkAssetChooseSelectItem" v-for="(item) in selectAssetCategoryList"
                 @click="showSelectView('asset')"
            >
              {{ item.name }}
            </div>
          </h-stack>
        </div>

        <div class="flllterChoose">
          <el-button style="height: 30px" size="mini" type="primary" @click="showTree('brand')"
                     :loading="loadBrand"
          >资产品牌
          </el-button>
          <h-stack flex-wrap="wrap" justify-content="flex-start" align-items="flex-start"
                   class="checkAssetChooseSelect"
          >
            <div class="checkAssetChooseSelectItem" v-for="(item) in selectBrandList" @click="showSelectView('brand')">
              {{ item.name }}
            </div>
          </h-stack>
        </div>

        <div class="flllterChoose">
          <el-button style="height: 30px" size="mini" type="primary" @click="showTree('model')"
                     :loading="loadModel"
          >资产型号
          </el-button>
          <h-stack flex-wrap="wrap" justify-content="flex-start" align-items="flex-start"
                   class="checkAssetChooseSelect"
          >
            <div class="checkAssetChooseSelectItem" v-for="(item) in selectModelList" @click="showSelectView('model')">
              {{ item.name }}
            </div>
          </h-stack>
        </div>

        <div class="flllterChoose">
          <el-button style="height: 30px" size="mini" type="primary" @click="showTree('org')"
                     :loading="loadOrg"
          >科室
          </el-button>
          <h-stack flex-wrap="wrap" justify-content="flex-start" align-items="flex-start"
                   class="checkAssetChooseSelect"
          >
            <div class="checkAssetChooseSelectItem" v-for="(item) in selectOrgList" @click="showSelectView('org')">
              {{ item.name }}
            </div>
          </h-stack>
        </div>

        <div class="flllterChoose">
          <el-button style="height: 30px" size="mini" type="primary" @click="showTree('simple')"
                     :loading="loadSimple"
          >手术室
          </el-button>
          <h-stack flex-wrap="wrap" justify-content="flex-start" align-items="flex-start"
                   class="checkAssetChooseSelect"
          >
            <div class="checkAssetChooseSelectItem" v-for="(item) in selectSimpleList" @click="showSelectView('simple')">
              {{ item.name }}
            </div>
          </h-stack>
        </div>


      </div>
      <v-stack justify-content="center" style="margin-right: 5px;">
        <el-button size="mini"
                   style="outline: none;margin-top: 5px;margin-bottom: 5px;background-color: #85ce61;"
                   class="fillterTitle"
                   @click="startSearch"
        >
          搜索
        </el-button>
      </v-stack>
    </h-stack>


    <div class="searchList">
      <div class="searchHead">
        查找结果({{ totalCount }}条)
      </div>
      <search-table
          :loading="loadSearch"
          height="calc(100% - 30px)"
          :table-data="searchList" @clickItem="clickItem"
      ></search-table>
    </div>


    <h-stack style="height: 40px;background-color: white">
      <el-pagination
          @current-change="currentChange"
          :page-size="pageSize"
          :pager-count="5"
          :current-page="page"
          layout="prev, pager, next"
          :total="totalCount"
      >
      </el-pagination>
    </h-stack>


<!--    <el-drawer-->
<!--        :modal="false"-->
<!--        size="calc(100% - 50px)"-->
<!--        :visible.sync="showDetail"-->
<!--        :with-header="false"-->
<!--        direction="btt"-->
<!--    >-->

<!--      <search-device-detail :device-detail="selectItem"></search-device-detail>-->

<!--    </el-drawer>-->

    <el-dialog  width="90%" title="" :visible.sync="showDetail">
      <div style="width: 100%;height: 600px">
        <search-device-detail :device-detail="selectItem"></search-device-detail>
      </div>
    </el-dialog>


    <vue-tree
        ref="vueTree"
        @dialogClose="dialogClose"
        @dialogSure="dialogSure"
        :expanded-keys="defaultCheckedKeys"
        :default-checked-keys="defaultCheckedKeys"
        :dialog-title="treeTilte"
        :data-list="dataList"
        :dialog-visible="dialogVisible"
    ></vue-tree>

  </div>
</template>

<script>

import mixin from '@/views/searchDevice/mixin/mixin'
import HStack from '@/components/hStack/hStack'
import VStack from '@/components/vStack/vStack'
import VueTree from '@/components/tree/vueTree'
import SearchTable from '@/views/searchDevice/searchTable/searchTable'
import SearchDeviceDetail from '@/views/searchDevice/searchDeviceDetail/searchDeviceDetail'

export default {
  name: 'searchDevice',
  components: { SearchDeviceDetail, SearchTable, VueTree, VStack, HStack },
  mixins: [mixin],
  data() {
    return {
      inPut: '',
      loadCategory: true,
      loadModel: true,
      loadBrand: true,
      loadSimple: true,
      dialogVisible: false,
      dialogType: '',
      categoryList: [],
      selectAssetCategoryList: [],
      brandList: [],
      selectBrandList: [],
      modelList: [],
      selectModelList: [],
      loadSearch: true,
      orgList: [], // 科室
      selectOrgList: [], // 被选择的科室
      simpleList: [], // 手术室
      selectSimpleList: [], // 被选择的手术室
      loadOrg: true,
      page: 1,
      pageSize: 50,
      totalCount: 0,
      searchList: [],
      showDetail: false,
      selectItem: {}
    }
  },
  computed: {
    treeTilte: function() {
      let title = ''
      if (this.dialogType == 'asset') {
        title = '资产分类'
      } else if (this.dialogType == 'brand') {
        title = '资产品牌'
      } else if (this.dialogType == 'org') {
        title = '科室'
      } else if (this.dialogType == 'simple') {
        title = '手术室'
      } else {
        title = '资产型号'
      }
      return title
    },
    dataList: function() {
      let list = []
      if (this.dialogType == 'asset') {
        list = this.categoryList
      } else if (this.dialogType == 'brand') {
        list = this.brandList
      } else if (this.dialogType == 'model') {
        list = this.modelList
      } else if (this.dialogType == 'org') {
        list = this.orgList
      } else if (this.dialogType == 'simple') {
        list = this.simpleList
      }
      return list
    },
    defaultCheckedKeys: function() {
      let list = []
      let selectList = []
      if (this.dialogType == 'asset') {
        selectList = this.selectAssetCategoryList
      } else if (this.dialogType == 'brand') {
        selectList = this.selectBrandList
      } else if (this.dialogType == 'model') {
        selectList = this.selectModelList
      } else if (this.dialogType == 'org') {
        selectList = this.selectOrgList
      } else if (this.dialogType == 'simple') {
        selectList = this.selectSimpleList
      }
      for (let i = 0; i < selectList.length; i++) {
        let asset = selectList[i]
        if (list.indexOf(asset.id) >= 0) {
          continue
        }
        list.push(asset.id)
      }
      return list
    }
  },
  mounted() {
    document.title = '查找资产'
    this.$nextTick(() => {
      this.getAllData()
      this.startSearch()
    })
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == 'login') {
      next(true)
      return
    }
    let leave = () => {
      if (this.showDetail) {
        this.showDetail = false
        next(false)
        return
      }
      next(true)
    }
    leave()
  },
  methods: {
    showTree: function(type) {
      if (typeof this.$refs.vueTree == 'undefined') {
        return
      }
      this.dialogType = type
      this.dialogVisible = true
      this.$refs.vueTree.showAllView()
    },
    showSelectView: function(type) {
      if (typeof this.$refs.vueTree == 'undefined') {
        return
      }
      this.dialogType = type
      this.dialogVisible = true
      this.$refs.vueTree.showAllView()
      this.$nextTick(() => {
        this.$refs.vueTree.showSelectView()
      })
    },
    clickItem: function(item) {
      this.showDetail = true
      this.selectItem = JSON.parse(JSON.stringify(item))
    },
    startSearch: function() {
      this.loadSearch = true
      this.searchDevice().then(res => {
        this.loadSearch = false
        this.searchList = res.list
        this.totalCount = res.totalCount
      })
    },
    currentChange: function(index) {
      this.page = index
      this.startSearch()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.searchDevice {
  width: 100%;
  height: 100%;
  background-color: lightgray;

  .searchFillter {
    width: 100%;
    background-color: white;

    .searchFillterBGView {
      width: calc(100% - 60px);
      margin-top: 5px;
      margin-bottom: 5px;
      margin-left: 10px;
      margin-right: 10px;

      .flllterChoose {
        float: left;
        width: 100%;
        margin-top: 5px;


        .checkAssetChooseSelect {
          float: right;
          width: calc(100% - 95px);
          margin-left: 5px;
          overflow: scroll;
          max-height: 30px;


          .checkAssetChooseSelectItem {
            padding-left: 5px;
            padding-right: 5px;
            word-wrap: break-word;
            margin-left: 5px;
            font-size: 13px;
            color: $color_primary;
            line-height: 28px;
            min-height: 28px;
            border-radius: 5px;
            margin-bottom: 2px;
          }
        }

      }

    }

    .fillterTitle {
      text-align: center;
      width: 70px;
      float: left;
      font-size: 14px;
      color: white;
      border-radius: 5px;
      background-color: $color_primary;
      border: none;
    }
  }

  .searchList {

    margin-top: 5px;
    width: 100%;
    height: calc(100% - 255px);
    background-color: white;

    .searchHead {

      width: 100%;
      height: 30px;
      font-size: 15px;
      text-align: center;
      line-height: 30px;
      color: $color_primary;

    }

  }

}

</style>
